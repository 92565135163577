import {onUnmounted, ref} from "vue";

const spinner = ref(false);

export default function usePageSpinner(stop_spinner_on_unmount = true) {
    function startSpinner() {
        spinner.value = true;
    }

    function stopSpinner() {
        spinner.value = false;
    }

    onUnmounted(() => {
       if(stop_spinner_on_unmount){
           stopSpinner();
       }
    });

    return {
        spinner,
        startSpinner,
        stopSpinner
    }
}
